@import 'node_modules/bootstrap/scss/bootstrap';
@import "variables";

:root {
  --bs-body-bg-rgb: 237, 242, 249;
  --bs-body-bg: rgb(var(--bs-body-bg-rgb));
}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.cdk-overlay-container {
  z-index: 1050;
}

.modal-xl .xl-content {
  height: 70vh;
}

.hover-show {

  & .to-show {
    display: none;
  }

  &:hover .to-show {
    display: flex;
  }
}

.hover-underline:hover {
  text-decoration: underline;
}

.pointer {
  cursor: pointer;
}

.notranslate {
}

.main-content {
  min-height: 100vh;
}

.dropdown-backdrop.dropdown.show:before {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba($modal-backdrop-bg,$modal-backdrop-opacity);
  content: '';
  z-index: 1030;
}

.row {
  .card-body {
    max-height: 330px;
    overflow: auto;
  }

  &.row-medium {
    .card-body {
      max-height: 350px;
    }
  }

  &.row-large {
    .card-body {
      max-height: 530px;
    }
  }
}